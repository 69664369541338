<template>
  <div class="taskdetails">
    <div class="task-title-content">
      <div class="task-title-text">
        <span v-if="data.taskDefinitionKey !== undefined" class="task-title-h2">
          {{ data.name }}
        </span>
        <span v-else>Aufgabendetails</span>
        <div class="task-subtitle-text">
          <span v-if="customerIds.length > 0" class="mr-2">
            Kundennummer:
            <template v-for="(customerId, index) of customerIds">
              <template v-if="index > 0">, </template>
              <a
                :key="customerId"
                :href="`/support-start?page=Kunden%C3%BCbersicht&system=VarioCRM&customerId=${customerId}`"
                target="_blank"
              >{{ customerId }}</a>
            </template>
          </span>
          <span v-if="contractIds.length > 0">Vertragsnummer: {{ contractIds.join(', ') }}</span>
        </div>
      </div>

      <div class="title-col">
        <div class="button-with-text">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="data.taskDefinitionKey !== null"
                small
                color="secondary"
                v-bind="attrs"
                v-on="on"
                @click="followUpModal = true"
              >
                <v-icon>mdi-calendar-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>Nachbearbeitungsdatum</span>
          </v-tooltip>
          <div>
            <span
              class="button-desc"
              :class="
                followUpDate !== null &&
                  new Date(followUpDate) < new Date(Date.now())
                  ? ' followUp-warning'
                  : ''
              "
            >
              {{
                followUpDate
                  ? new Date(followUpDate).toLocaleString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  })
                  : 'Nachbearbeitungsdatum'
              }}
            </span>
          </div>
        </div>
        <v-dialog
          ref="dialog"
          v-model="followUpModal"
          :return-value.sync="followUpDate"
          persistent
          width="290px"
        >
          <v-date-picker
            v-model="followUpDate"
            scrollable
            :min="minDate"
            locale="de-DE"
          >
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              small
              @click="deleteFollowUp"
            >
              Löschen
            </v-btn>
            <v-btn
              text
              small
              @click="followUpModal = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="updateFollowUp"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div class="button-with-text title-col">
        <comment-container
          :objectType="commentObjectType"
          :objectId="data.id"
          :objectInfo="data.name"
        ></comment-container>
      </div>

      <div class="button-with-text title-col">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="data.taskDefinitionKey !== null"
              small
              color="secondary"
              v-bind="attrs"
              v-on="on"
              @click="showProcess"
            >
              <v-icon>mdi-monitor-shimmer</v-icon>
            </v-btn>
          </template>
          <span>Prozess anzeigen</span>
        </v-tooltip>
        <div><span class="button-desc">Prozess anzeigen</span></div>
      </div>

      <div v-if="!isSupervisor" class="button-with-text title-col">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="data.taskDefinitionKey !== null"
              color="secondary"
              small
              v-bind="attrs"
              :disabled="assigned && data.assignee !== userId"
              v-on="on"
              @click="toggleClaim"
            >
              <v-icon>
                {{ assigned ? 'mdi-clipboard-arrow-up' : 'mdi-clipboard-edit' }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ assigned ? 'Aufgabe abgeben' : 'Aufgabe übernehmen' }}
          </span>
        </v-tooltip>
        <div>
          <span class="button-desc">
            <v-icon v-if="assigned">mdi-account</v-icon>
            {{ assigned ? data.assignee.toUpperCase() : 'Nicht zugewiesen' }}
          </span>
        </div>
      </div>

      <div v-else class="button-with-text title-col">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-if="data.taskDefinitionKey !== null"
                  color="secondary"
                  small
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  <v-icon>mdi-clipboard-account</v-icon>
                </v-btn>
              </template>
              <span> Aufgabe zuweisen </span>
            </v-tooltip>
          </template>
          <v-list class="user-selection-menu">
            <v-list-item-group :value="selectedAssignee">
              <template v-for="(candidateUser, i) in assignableUsers">
                <v-list-item
                  :key="candidateUser.id"
                  :value="candidateUser"
                  @click="assign(candidateUser)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{
                        candidateUser.firstName + ' ' + candidateUser.lastName
                      }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ candidateUser.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="candidateUser.id === data.assignee">
                    <v-icon>mdi-check-bold</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                  v-if="i < assignableUsers.length - 1"
                  :key="`divider-${i}`"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <div>
          <span class="button-desc">
            <v-icon v-if="assigned">mdi-account</v-icon>
            {{ assigned ? data.assignee.toUpperCase() : 'Nicht zugewiesen' }}
          </span>
        </div>
      </div>

      <v-btn
        icon
        plain
        x-large
        @click="$emit('onClose')"
      >
        <v-icon> mdi-close-thick </v-icon>
      </v-btn>
    </div>

    <v-alert
      v-show="data.suspended"
      prominent
      class="mt-4 text-body-1"
      elevation="5"
      type="warning"
      icon="mdi-pause-circle-outline"
    >
      Der Prozess ist derzeit pausiert, dadurch ist diese Aufgabe read-only und kann weder bearbeitet noch zugewiesen werden.<br />
      Wenn es sich hierbei um einen Prozess/Vertrag/Kunden handelt der weiter prozessiert werden soll,
      bitte mit der Information wieso es sich hier ggf. um einen Fehler handelt in Teams unter <span class="indigo--text text--darken-2">Support SPOT</span> melden.
    </v-alert>

    <ActionCustomerDataValidation
      v-if="taskDefinitionKey === 'InteressentAnlegenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionPaymentValidation
      v-else-if="taskDefinitionKey === 'BonitatsberichtUberprufenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCheckIfCustomerInFibreTwistExpansionArea
      v-else-if="
        taskDefinitionKey === 'AusbaugebietUndKundenAdresseUberprufenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCarrierValidation
      v-else-if="taskDefinitionKey === 'CarrierDatenKorrigierenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionNewHouseConnectionOrderValidation
      v-else-if="
        data.taskDefinitionKey ===
          'EinwilligungZusatzlicherHausanschlussauftragEinholenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionHandlePortingManually
      v-else-if="taskDefinitionKey === 'PortierungManuellBearbeitenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionProcessUnknownEkp
      v-else-if="
        data.taskDefinitionKey ===
          'RufnummernMitUnbekanntenEKPNachbearbeitenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />

    <ActionCancelPortingProcess
      v-else-if="taskDefinitionKey === 'StornoanfrageBearbeitenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionValidationOfPhoneNumberPublication
      v-else-if="taskDefinitionKey === 'VeroffentlichungUberprufenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionEditCustomerData
      v-else-if="taskDefinitionKey === 'KundendatenBearbeitenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionMSANProvisioning
      v-else-if="taskDefinitionKey === 'MSANPortUberprfenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionMissingInfoPortingData
      v-else-if="taskDefinitionKey === 'PortierungsdatenBearbeitenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionInternetCheckCustomerConnectivity
      v-else-if="
        taskDefinitionKey === 'OnlinestatusInternetUberprufenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionAddCpeId
      v-else-if="taskDefinitionKey === 'CPERessourcenNachtragenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionFinalizeOrder
      v-else-if="
        taskDefinitionKey === 'VertragsinformationenNachbearbeitenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionFinalizeOrder
      v-else-if="taskDefinitionKey === 'NichtFtthVertrageBehandelnTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionValidationOfPhoneRegistration
      v-else-if="
        taskDefinitionKey === 'OnlinestatusTelefonieUberprufenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCheckDeactivatedNumbers
      v-else-if="taskDefinitionKey === 'DeaktivierteRufnummernPrufenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionComfortInstallation
      v-else-if="taskDefinitionKey === 'InstallationsterminDefinierenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionHandlePortingCancellationManually
      v-else-if="taskDefinitionKey === 'PortierungManuellStornierenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionEditPortingCancellation
      v-else-if="taskDefinitionKey === 'StornierungManuellBearbeitenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionSendDevicesToCustomer
      v-else-if="
        taskDefinitionKey === 'ONTUndGerateFurAlleDiensteVerschickenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionValidateRouterProduct
      v-else-if="taskDefinitionKey === 'ValidateRouterProductUserTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionOCSAddressCheck
      v-else-if="taskDefinitionKey === 'AdresseImOCSOderVarioCRMAnpassen'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionStockLow
      v-else-if="taskDefinitionKey === 'SelocaWarenbestandPrufenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCheckCostChanging
      v-else-if="taskDefinitionKey === 'KostenanderungUberprufenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionReleasePropertyStateChange
      v-else-if="taskDefinitionKey === 'ReleasePropertyStateChange'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionReleaseProjectStateChange
      v-else-if="taskDefinitionKey === 'ReleaseProjectStateChange'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionRejectPropertyStateChange
      v-else-if="
        taskDefinitionKey === 'ProjectStateChangeRejectedNotification'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCheckPotentialContractDuplicates
      v-else-if="
        taskDefinitionKey === 'VertragDuplikatUeberpruefungTask' ||
          taskDefinitionKey === 'PhaseInVertragDuplikatUeberpruefungTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionVerifyGoe2000Tariff
      v-else-if="taskDefinitionKey === 'UeberpruefungGoe2000Tarif'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionHandleContractActivatedDeactivatedPropertyState
      v-else-if="
        data.taskDefinitionKey ===
          'AktiviertenOderDeaktiviertenVertragBehandlenHumanTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionHandleManuallyOrderedArticle
      v-else-if="taskDefinitionKey === 'HandleManuallyOrderedArticleTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionCheckNumberBlock
      v-else-if="taskDefinitionKey === 'CheckNumberBlockTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionGncExportReady
      v-else-if="taskDefinitionKey === 'GncExportReadyTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionDowngradeAnbietenTask
      v-else-if="taskDefinitionKey === 'DowngradeAnbietenTask'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionManualOntOnlineCheck
      v-else-if="taskDefinitionKey === 'ONTOnlineCheckManualCheck'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionDecideIfNewHomeIdIsRequired
      v-else-if="
        data.taskDefinitionKey ===
          'HomeIdNeuVergebenOderExistierendeVerwendenTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionManualOntOnlineCheckNew
      v-else-if="taskDefinitionKey === 'ONTOnlineCheckManualCheckNew'"
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionNegativeDateShiftFeedback
      v-else-if="
        taskDefinitionKey === 'NegativeTerminVerschiebungsAntwortHumanTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionOntResourceMissing
      v-else-if="
        taskDefinitionKey === 'OntResourceMissingUserTask'
      "
      :key="data.id"
      :data="data"
      @onClose="$emit('onClose')"
    />
    <ActionDefaultTask v-else :data="data" />
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import { OBJECT_TYPE } from '@/main/Comment.js';
import NotificationObject from '@/main/NotificationObject.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import { LocalDate } from '@js-joda/core';

import ImageButton from '@/components/elements/ImageButton.vue';
import GoetelButton from '@/components/elements/GoetelButton.vue';
import CommentContainer from '@/components/comments/CommentContainer.vue';

import ProcessViewer from '@/_deprecated/ProcessViewer.vue';
import ActionCancelPortingProcess from './porting/ActionCancelPortingProcess.vue';
import ActionNegativeDateShiftFeedback from './porting/ActionNegativeDateShiftFeedback.vue';
import ActionCustomerDataValidation from '@/_obsolete/ActionCustomerDataValidation.vue';
import ActionPaymentValidation from './orderEntry/ActionPaymentValidation.vue';
import ActionCheckIfCustomerInFibreTwistExpansionArea from './orderEntry/ActionCheckIfCustomerInFibreTwistExpansionArea.vue';
import ActionVerifyGoe2000Tariff from './orderEntry/ActionVerifyGoe2000Tariff.vue';
import ActionComfortInstallation from './orderEntry/ActionComfortInstallation.vue';
import ActionHandlePortingCancellationManually from './dataCleanup/ActionHandlePortingCancellationManually.vue';
import ActionEditPortingCancellation from './dataCleanup/ActionEditPortingCancellation.vue';
import ActionSendDevicesToCustomer from './deliveryBySeloca/ActionSendDevicesToCustomer.vue';
import ActionHandleManuallyOrderedArticle from './deliveryBySeloca/ActionHandleManuallyOrderedArticle.vue';
import ActionHandleContractActivatedDeactivatedPropertyState from './orderEntry/ActionHandleContractActivatedDeactivatedPropertyState.vue';
import ActionDecideIfNewHomeIdIsRequired from './orderEntry/ActionDecideIfNewHomeIdIsRequired.vue';

import ActionNewHouseConnectionOrderValidation from './orderEntry/ActionNewHouseConnectionOrderValidation.vue';
import ActionFinalizeOrder from './orderEntry/ActionFinalizeOrder.vue';
import ActionCheckPotentialContractDuplicates from './orderEntry/ActionCheckPotentialContractDuplicates.vue';
import ActionDefaultTask from './ActionDefaultTask.vue';
import ActionValidationOfPhoneNumberPublication from './porting/ActionValidationOfPhoneNumberPublication.vue';
import ActionProcessUnknownEkp from './porting/ActionProcessUnknownEkp.vue';
import ActionCarrierValidation from './porting/ActionCarrierValidation.vue';
import ActionHandlePortingManually from './porting/ActionHandlePortingManually.vue';
import ActionEditCustomerData from './porting/ActionEditCustomerData.vue';
import ActionValidationOfPhoneRegistration from './provisioning/ActionValidationOfPhoneRegistration.vue';
import ActionAddCpeId from './dataCleanup/ActionAddCpeId.vue';
import ActionCheckDeactivatedNumbers from './dataCleanup/ActionCheckDeactivatedNumbers.vue';
// MSAN Provisioning
import ActionMSANProvisioning from './provisioning/ActionMSANProvisioning.vue';
import ActionInternetCheckCustomerConnectivity from './provisioning/ActionInternetCheckCustomerConnectivity.vue';
import ActionMissingInfoPortingData from './provisioning/ActionMissingInfoPortingData.vue';
import ActionOCSAddressCheck from './porting/ActionOCSAddressCheck.vue';
import ActionCheckCostChanging from './porting/ActionCheckCostChanging.vue';
import ActionStockLow from './deliveryBySeloca/ActionStockLow.vue';
import ActionReleasePropertyStateChange from './constructionProjects/ActionReleasePropertyStateChange.vue';
import ActionReleaseProjectStateChange from './constructionProjects/ActionReleaseProjectStateChange.vue';
import ActionRejectPropertyStateChange from './constructionProjects/ActionRejectStateChange.vue';

import ActionManualOntOnlineCheck from './provisioning/ActionManualOntOnlineCheck.vue';
import ActionManualOntOnlineCheckNew from './provisioning/ActionManualOntOnlineCheckNew.vue';

export default {
  name: 'TaskContent',
  components: {
    DatePicker,
    ImageButton,
    GoetelButton,
    CommentContainer,
    ActionInternetCheckCustomerConnectivity,
    ActionCancelPortingProcess,
    ActionNegativeDateShiftFeedback,
    ActionValidationOfPhoneNumberPublication,
    ActionCustomerDataValidation,
    ActionPaymentValidation,
    ActionSendDevicesToCustomer,
    ActionCarrierValidation,
    ActionFinalizeOrder,
    ActionNewHouseConnectionOrderValidation,
    ActionProcessUnknownEkp,
    ActionDefaultTask,
    ActionHandlePortingManually,
    ActionEditCustomerData,
    ActionMSANProvisioning,
    ActionMissingInfoPortingData,
    ActionValidationOfPhoneRegistration,
    ActionCheckIfCustomerInFibreTwistExpansionArea,
    ActionAddCpeId,
    ActionCheckDeactivatedNumbers,
    ActionComfortInstallation,
    ActionEditPortingCancellation,
    ActionHandlePortingCancellationManually,
    ActionOCSAddressCheck,
    ActionCheckCostChanging,
    ActionStockLow,
    ActionReleasePropertyStateChange,
    ActionReleaseProjectStateChange,
    ActionRejectPropertyStateChange,
    ActionVerifyGoe2000Tariff,
    ActionCheckPotentialContractDuplicates,
    ActionHandleManuallyOrderedArticle,
    ActionHandleContractActivatedDeactivatedPropertyState,
    ActionDecideIfNewHomeIdIsRequired,
    ActionManualOntOnlineCheck,
    ActionManualOntOnlineCheckNew,
    ActionCheckNumberBlock: () =>
      import('./generalUserTasks/ActionCheckNumberBlock.vue'),
    ActionGncExportReady: () =>
      import('./generalUserTasks/ActionGncExportReady.vue'),
    ActionDowngradeAnbietenTask: () => import('./orderEntry/ActionDowngradeAnbietenTask.vue'),
    ActionValidateRouterProduct: () => import('./deliveryBySeloca/ActionValidateRouterProduct.vue'),
    ActionOntResourceMissing: () => import('./orderEntry/ActionOntResourceMissing.vue'),
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    contractIds: [],
    customerIds: [],
    taskDefinitionKey: undefined,
    commentObjectType: OBJECT_TYPE.USER_TASK,
    publicPath: process.env.BASE_URL,
    processDefinitionFileName: 'Kundenanschaltung_Eingang_Diensteauftrag.bpmn',
    followUpDate: null,
    followUpModal: false,
    leadGroups: [
      'LgSpotPortierungLeitung',
      'LgSpotProvisionierungLeitung',
      'LgSpotAccessLeitung',
      'LgSpotSecondLevelLeitung',
      'LgSpotAuftragsmanagementLeitung',
      'LgSpotBauLeitung',
      'LgSpotBusinessCustomerLeitung',
      'LgSpotClearingLeitung',
      'camunda-admin'
    ],
    assignableUsers: []
  }),
  computed: {
    userId() {
      return this.$store.getters.getUsername;
    },
    minDate() {
      const minDate = LocalDate.now();
      return minDate.toString();
    },
    claimed() {
      return this.data.assignee === this.userId;
    },
    assigned() {
      return this.data.assignee !== null;
    },
    isSupervisor() {
      return this.$auth.check(this.leadGroups);
    },
    selectedAssignee() {
      if (this.data.assignee) {
        return this.assignableUsers.find(
          (user) => user.id === this.data.assignee
        );
      } else {
        return undefined;
      }
    }
  },
  watch: {
    data: function (newData, oldData) {
      this.retrieveProcessFileName(newData.processDefinitionId);
      this.retrieveAssignableUsers();
      if (this.data.followUp) {
        this.followUpDate = this.data.followUp.split('T')[0];
      } else {
        this.followUpDate = null;
      }
      this.data.variables = this.fetchTaskVariables();
      this.taskDefinitionKey = this.data.taskDefinitionKey;
    }
  },
  mounted: function () {
    this.retrieveProcessFileName(this.$props.data.processDefinitionId);
    this.retrieveAssignableUsers();
    if (this.data.followUp) {
      this.followUpDate = this.data.followUp.split('T')[0];
    }
    this.data.variables = this.fetchTaskVariables();
    this.taskDefinitionKey = this.data.taskDefinitionKey;
  },
  methods: {
    showProcess: function () {
      this.$modal.show(
        ProcessViewer,
        {
          src: this.processDefinitionFileName,
          isModal: true
        },
        {
          width: '80%',
          height: '80%',
          maxWidth: 1250,
          maxHeight: 800,
          adaptive: true
        }
      );
    },
    showError: function (description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject('error', description)
      );
    },
    toggleClaim: function () {
      if (this.assigned) {
        this.unclaim();
      } else {
        this.claim();
      }
    },
    assign: function (user) {
      // If the selected user is already assigned, unassign instead
      if (user.id === this.data.assignee) {
        this.unclaim();
      } else {
        HTTP.post(`/engine/default/task/${this.data.id}/assignee`, {
          userId: user.id
        })
          .then((resp) => this.refreshData())
          .catch(() =>
            this.showError('Die Aufgabe konnte nicht übernommen werden!')
          );
      }
    },
    claim: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/claim`, {
        userId: this.userId
      })
        .then((resp) => this.refreshData())
        .catch(() =>
          this.showError('Die Aufgabe konnte nicht übernommen werden!')
        );
    },
    unclaim: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/unclaim`, {})
        .then((resp) => this.refreshData())
        .catch(() =>
          this.showError('Die Aufgabe konnte nicht abgegeben werden!')
        );
    },
    refreshData: function () {
      HTTP.get(`/engine/default/task/${this.data.id}`)
        .then((resp) => {
          this.$emit('onUpdateEntry', resp.data);
          this.retrieveAssignableUsers();
        })
        .catch(() =>
          this.showError('Die Aufgabe konnte nicht aktualisiert werden!')
        );
    },
    // Retrieves a list of users that can be assigned to the current task. Will only contain elements if the current user is a supervisor or admin!
    retrieveAssignableUsers: function () {
      HTTP.get(`/tasks/candidates/${this.data.id}`).then((resp) => {
        const candidates = resp.data;
        const currentUser = candidates.filter(
          (candidate) => candidate.id === this.userId
        );
        this.assignableUsers = currentUser.concat(
          candidates
            .filter((candidate) => candidate.id !== this.userId)
            .sort((candidateA, candidateB) => {
              if (candidateA.id < candidateB.id) {
                return -1;
              } else if (candidateA.id > candidateB.id) {
                return 1;
              } else {
                return 0;
              }
            })
        );
      });
    },
    updateFollowUp() {
      this.$refs.dialog.save(this.followUpDate);
      const val = this.followUpDate;
      HTTP.put(`/task/${this.data.id}`, {
        ...this.data,
        followUp: `${val}T00:00:00.000+0000`
      }).then((res) => {
        this.$emit('refresh');
      });
    },
    deleteFollowUp() {
      this.$refs.dialog.save(null);
      HTTP.put(`/task/${this.data.id}`, {
        ...this.data,
        followUp: null
      }).then((res) => {
        this.$emit('refresh');
      });
    },
    retrieveProcessFileName: function (processDefinitionId) {
      HTTP.get(`/process-definition/${processDefinitionId}`)
        .then((resp) => {
          this.processDefinitionFileName = resp.data.resource;
        })
        .catch(() => {
          this.showError('Die Prozessdefinition konnte nicht geladen werden!');
        });
    },
    fetchTaskVariables: function () {
      return new Promise((resolve, reject) => {
        HTTP.get(`/task/${this.data.id}/variables`)
          .then(({ data }) => {
            this.contractIds = [...new Set(Object.keys(data).filter((k) => /contractId/gi.test(k)).map((k) => data[k].value).filter((i) => Number.isInteger(i)))];
            this.customerIds = [...new Set(Object.keys(data).filter((k) => /customerId/gi.test(k)).map((k) => data[k].value).filter((i) => Number.isInteger(i)))];

            return resolve(data);
          })
          .catch((err) => {
            this.showError('Die Prozessvariablen konnten nicht geladen werden!');
            return reject(err);
          });
      });
    }
  }
};
</script>

<style scoped>
h2 {
  display: grid;
  grid-template-columns: auto max-content;
}

.task-title .label {
  font-size: var(--goe-fontSize-verySmall);
  text-transform: none;
}

.user-selection-menu .v-list-item--active {
  color: var(--v-primary-lighten1) !important;
}
.task-title-content {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 1em;
  font-weight: bold;
  background-color: var(--goe-background-highlight);
}
.task-subtitle-text {
  font-weight: 400;
  font-size: 0.875rem;
  color: var(--goe-fontColor-dark);
}
.task-title-text {
  margin-right: auto;
}
.title-col {
  width: 10%;
  min-width: 134px;
}
.followUp-warning {
  color: #eb5252;
}
.task-title-h2 {
  font-size: 24px;
  margin-right: 1em;
}
.button-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.05em;
}
.button-with-text > .v-btn {
  width: max-content;
}
.button-with-text span.button-desc {
  font-size: 12px;
  white-space: nowrap;
}
div.task-title-content div.button-with-text .v-btn {
  min-height: 35px;
  min-width: 75px;
}
@media (max-width: 1400px) {
  .task-title-content {
    font-size: 16px;
  }
  .task-title-h2 {
    font-size: 18px;
  }
}
</style>
