<template>
  <div @click="select">
    <v-list-item :value="data.id">
      <v-list-item-content>
        <v-list-item-title>{{ data.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ data.assignee ? data.assignee : 'Nicht zugewiesen' }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-show="data.suspended">
          <v-icon style="margin-bottom: 0.2em; margin-right: 0.2em;" color="warning">
            mdi-pause-circle-outline
          </v-icon>
          <span>Der Prozess wurde pausiert</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-show="data.followUp">
          <v-icon
            style="margin-bottom: 0.2em"
            size="16"
          >
            mdi-calendar-arrow-right
          </v-icon>
          <span
            :class="
              new Date(data.followUp) < new Date(Date.now())
                ? 'followUp-warning'
                : ''
            "
          >
            {{
              data.followUp
                ? new Date(data.followUp).toLocaleString('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })
                : ''
            }}
          </span>
        </v-list-item-subtitle>
        <div class="created">
          <div>{{ getFormattedDuration() }}</div>
          <div />
        </div>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import Logger from '@/main/Logger.js';

const LOGGER_KEY = 'TASKITEM';

export default {
  name: 'Taskitem',
  props: {
    data: {
      type: Object,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    select: function () {
      const data = this.$props.data;
      Logger.log(LOGGER_KEY, 'select new entry', data.id);

      this.$emit('onSelect', data);
    },
    getFormattedDuration: function () {
      return this.formatDuration(
        new Date(this.$props.data.created),
        new Date()
      );
    },
    formatDuration: function (timeA, timeB) {
      var duration = Math.abs(timeA.getTime() - timeB.getTime());
      const milliseconds = 1000;

      const fullMinute = 60 * milliseconds;
      const fullHour = 60 * fullMinute;
      const fullDay = 24 * fullHour;

      if (duration < fullMinute) {
        return 'Kürzlich erstellt';
      }

      let suffix, value;

      if (duration > fullDay) {
        value = duration / fullDay;
        suffix = value < 2 ? 'einem Tag' : `${Math.floor(value)} Tagen`;
      } else if (duration > fullHour) {
        value = duration / fullHour;
        suffix = value < 2 ? 'einer Stunde' : `${Math.floor(value)} Stunden`;
      } else {
        value = duration / fullMinute;
        suffix = value < 2 ? 'einer Minute' : `${Math.floor(value)} Minuten`;
      }

      return `Erstellt vor ${suffix}`;
    }
  }
};
</script>

<style scoped>
.taskitem {
  cursor: pointer;
  padding: var(--goe-spacing-2);
  border-bottom: var(--goe-borderSize-small) solid var(--goe-borderColor-clear);
  border-left: var(--goe-borderSize-large) solid transparent;
  user-select: none;
}

.selected {
  border-right: var(--goe-borderSize-large) solid var(--goe-background-dark);
}

.description,
.created {
  color: var(--goe-fontColor-light);
}

.description,
.created {
  display: grid;
  grid-template-columns: auto max-content;
  font-size: var(--goe-fontSize-small);
}
.taskitem-title {
  font-size: 16px;
}
.taskitem-assignee {
  font-size: 14px;
}
.followUp-warning {
  color: #eb5252;
}
.v-item--active {
  color: var(--v-primary-lighten1) !important;
}
.v-list {
  padding: 0;
}
</style>
