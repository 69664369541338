<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div v-if="!isLoading">
        <div class="group">
          <div>Aufgabendetails</div>

          <div>
            <div class="space">
              <div class="label">
                Aufgabenname
              </div>
              <div>{{ data.name }}</div>
            </div>

            <div class="space">
              <div class="label">
                Zugewiesen an
              </div>
              <div>
                <span v-if="data.assignee !== null">{{ data.assignee }}</span>
                <span v-else>Nicht zugewiesen.</span>
              </div>
            </div>

            <div class="space">
              <div class="label">
                Beschreibung
              </div>
              <div>
                <span v-if="data.description !== null">{{
                  data.description
                }}</span>
                <span v-else>Keine Beschreibung vorhanden. Der Task ist noch nicht implementiert. Weitere Fragen bitte an die Entwicklung stellen</span>
              </div>
            </div>
          </div>
        </div>

        <div class="group">
          <div>Kommentare</div>

          <div v-if="comments.length > 0" class="comment-wrapper">
            <div
              v-for="item in comments"
              :key="item.id"
              class="comment"
            >
              <div class="label">
                {{ item.userId }}
              </div>
              <div>{{ item.message }}</div>
            </div>
          </div>

          <div v-else>
            Keine Kommentare vorhanden.
          </div>
        </div>

        <GoetelInput
          isMandatory
          description="Kommentar"
          type="text"
          inputId="comment"
          elementType="textarea"
          :currentErrors="errors"
          :value="comment"
          :visitedInputFields="visited"
          @onChange="comment = $event"
          @onError="errors = $event"
          @onVisited="visited = $event"
        />
      </div>

      <div v-else>
        <Loader />
      </div>

      <div v-if="!isLoading" class="buttons-wrapper">
        <NormalButton
          description="Aufgabe schließen"
          buttonStyle="danger"
          @onClick="completeTask"
        />
        <NormalButton
          :disabled="errors.length !== 0 || visited.length === 0"
          description="Kommentieren"
          buttonStyle="clear"
          @onClick="commentTask"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import GoetelInput from '@/components/elements/GoetelInput.vue';
import NormalButton from '@/components/elements/NormalButton.vue';
import Loader from '@/components/elements/Loader.vue';

export default {
  name: 'ActionDefaultTask',
  components: { Loader, NormalButton, GoetelInput },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    errors: [],
    visited: [],
    comments: [],
    comment: ''
  }),
  created: function () {
    this.loadComments();
  },
  methods: {
    showNotification: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    loadComments: function () {
      this.isLoading = true;
      HTTP.get(`/engine/default/task/${this.data.id}/comment`)
        .then((resp) => {
          this.comments = resp.data.sort(
            (itemA, itemB) => new Date(itemA.time) - new Date(itemB.time)
          );
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Die Kommentare der Aufgabe konnten nicht erfolgreich geladen werden!'
          )
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
    commentTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/comment/create`, {
        message: this.comment
      })
        .then(() => {
          this.comment = '';
          this.visited = [];
          this.loadComments();
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Der Kommentar konnte der Aufgabe nicht erfolgreich hinzugefügt werden!'
          )
        );
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        variables: {
          order: {
            type: 'String',
            value: this.comment
          }
        }
      })
        .then((resp) => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showNotification(
            'error',
            'Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!'
          )
        );
    }
  }
};
</script>

<style scoped>
.loader {
  margin: auto;
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.comment {
  margin-bottom: var(--goe-spacing-2);
}

.comment-wrapper > div:last-child {
  margin-bottom: var(--goe-spacing-0);
}
</style>
