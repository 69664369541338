<template>
  <div v-if="assignee !== username" class="blocker">
    <div>
      <v-icon color="var(--goe-color-error)">
        mdi-lock-alert
      </v-icon>
      <div>
        Sie müssen die Aufgabe erst übernehmen bevor Sie diese bearbeiten
        können.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blocker',
  props: {
    assignee: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    username() {
      return this.$store.getters.getUsername;
    }
  }
};
</script>

<style scoped>
.blocker {
  background: var(--goe-background-clearHover);
  cursor: not-allowed;
  display: block;
  position: relative;
  top: -100%;
  width: 100%;
  height: 100%;
  left: var(--goe-spacing-0);
  right: auto;
  bottom: auto;
  opacity: 0.7;
  background: var(--goe-background-light);
}

.blocker > div {
  font-size: var(--goe-fontSize-normal);
  line-height: var(--goe-spacing-5);
  color: var(--goe-fontColor-dark);
  user-select: none;
  display: flex;
}
.blocker > div > div {
  margin-left: var(--goe-spacing-1);
}

@media (max-width: 1400px) {
  .blocker {
    font-size: 16px;
  }
}
</style>
