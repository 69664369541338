var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"taskheader"},[_c('v-text-field',{attrs:{"label":"Suche","height":"40","clearable":""},on:{"input":_vm.onSearchChange},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('div',{staticClass:"button-wrapper"},[[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","color":"secondary","small":""},on:{"click":function($event){return _vm.onSort()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.sortIcon))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.sortTooltip))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"fab":"","color":"secondary","small":""},on:{"click":function($event){return _vm.$emit('onRefresh')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Aufgabenliste aktualisieren")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"primary","small":""},on:{"click":function($event){return _vm.$emit('onCreateTask')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-new-box")])],1)]}}])},[_c('span',[_vm._v("Neue Aufgabe erstellen")])])]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }