<template>
  <div>
    <div class="taskheader">
      <!-- <v-row no-gutters>
      <v-col align-self="stretch"> -->
      <v-text-field
        v-model="searchString"
        label="Suche"
        height="40"
        clearable
        @input="onSearchChange"
      >
      </v-text-field>
      <div class="button-wrapper">
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="secondary"
                small
                class="mr-1"
                v-bind="attrs"
                @click="onSort()"
                v-on="on"
              >
                <v-icon>{{ sortIcon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ sortTooltip }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="secondary"
                small
                class="mr-1"
                v-bind="attrs"
                v-on="on"
                @click="$emit('onRefresh')"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Aufgabenliste aktualisieren</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="primary"
                small
                v-bind="attrs"
                @click="$emit('onCreateTask')"
                v-on="on"
              >
                <v-icon>mdi-new-box</v-icon>
              </v-btn>
            </template>
            <span>Neue Aufgabe erstellen</span>
          </v-tooltip>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import GoetelInput from '@/components/elements/GoetelInput.vue';
import ImageButton from '@/components/elements/ImageButton.vue';

export default {
  name: 'Taskheader',
  components: {
    GoetelInput,
    ImageButton
  },
  props: {
    selectedTaskTypeFilters: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    searchString: undefined,
    sortIndex: 0,
    sortFollowUp: false,
    searchTimeoutId: null
  }),
  computed: {
    sortIcon() {
      switch (this.sortIndex) {
        case 0:
          return 'mdi-sort';
        case 1:
          return 'mdi-sort-ascending';
        case 2:
          return 'mdi-sort-descending';
        default:
          return '';
      }
    },
    sortTooltip() {
      switch (this.sortIndex) {
        case 0:
          return 'Sortierung: Fälligkeit';
        case 1:
          return 'Sortierung: Erstellungsdatum absteigend';
        case 2:
          return 'Sortierung: Erstellungsdatum aufsteigend';
        default:
          return '';
      }
    }
  },
  methods: {
    onSearchChange: function (value) {
      if (this.searchTimeoutId) clearTimeout(this.searchTimeoutId);
      this.searchTimeoutId = setTimeout(() => {
        const regExp = this.searchString;
        this.$emit('onSearchChanged', regExp);
      }, 1000);
    },
    onSort() {
      if (this.sortIndex === 2) {
        this.sortIndex = 0;
      } else {
        this.sortIndex++;
      }
      this.$emit('onSort', this.sortIndex);
    }
  }
};
</script>

<style scoped>
.taskheader {
  display: flex;
  margin: 0.5em 1em;
  width: 17em;
  transition: width 1s ease-in;
}

.taskheader .button-wrapper {
  margin-top: 20px;
  flex: 1 0 auto;
  margin-left: 9px;
}

.taskheader button {
  margin-top: auto;
}
.taskheader input,
.taskheader div,
.taskheader button {
  margin-bottom: var(--goe-spacing-0);
}
</style>
