<template>
  <div id="list">
    <TaskHeader
      :selectedTaskTypeFilters="selectedTaskTypeFilters"
      @onRefresh="$emit('onRefresh')"
      @onCreateTask="$emit('onCreateTask')"
      @onSort="onSortIndexChanged($event)"
      @onSearchChanged="onSearchChanged($event)"
    />

    <v-list v-if="!isLoading" shaped>
      <v-list-item-group
        id="list-content"
        v-model="selectedItem"
        color="primary"
        :mandatory="selectedItem !== undefined ? true : false"
      >
        <TaskItem
          v-for="item in visiblePages"
          :key="item.id"
          :data="item"
          :selectedId="selectedId"
          @onSelect="$emit('onSelect', $event)"
        />

        <v-pagination
          v-model="page"
          :length="Math.ceil((count + 1) / perPage)"
          total-visible="5"
        ></v-pagination>

        <div v-if="filteredItems.length === 0" class="no-entries">
          Keine Einträge vorhanden
        </div>
      </v-list-item-group>
    </v-list>

    <Loader v-else />
  </div>
</template>

<script>
import TaskItem from './TaskItem.vue';
import TaskHeader from './TaskHeader.vue';

import Loader from '@/components/elements/Loader.vue';

export default {
  name: 'Tasklist',
  components: {
    TaskItem,
    TaskHeader,
    Loader
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    selectedId: {
      type: String,
      required: false,
      default: null
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    selectedTaskTypeFilters: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    regExp: '',
    sortIndex: 0,
    selectedItem: undefined,
    page: 1,
    perPage: 15
  }),
  computed: {
    sortedItems() {
      const items = this.items;
      const currentDate = new Date();
      // sort by followUp
      let itemPool;
      if (this.sortIndex === 0) {
        const followUpDue = items
          .filter(
            (item) =>
              item.camundaTaskDto.followUp !== null &&
              new Date(item.camundaTaskDto.followUp) <= currentDate
          )
          .sort(this.sortFunctionFollowUpAsc);
        const noFollowUp = items
          .filter((item) => item.camundaTaskDto.followUp === null)
          .sort(this.sortFunctionCreationDateDesc);
        const followUpPending = items
          .filter(
            (item) =>
              item.camundaTaskDto.followUp !== null &&
              new Date(item.camundaTaskDto.followUp) > currentDate
          )
          .sort(this.sortFunctionFollowUpAsc);
        itemPool = followUpDue.concat(noFollowUp).concat(followUpPending);
      } else if (this.sortIndex === 1) {
        itemPool = items.sort(this.sortFunctionCreationDateDesc);
      } else {
        itemPool = items.sort(this.sortFunctionCreationDateAsc);
      }
      return itemPool;
    },
    filteredItems() {
      return this.sortedItems.filter((item) =>
        [item.camundaTaskDto.name, item.camundaTaskDto.assignee]
          .concat(item.searchTerms)
          .join(' ')
      );
    },
    visiblePages() {
      let lowerPageIndex = (this.page - 1) * this.perPage;
      let upperPageIndex = this.page * this.perPage;
      if (lowerPageIndex >= this.filteredItems.length) {
        lowerPageIndex = 0;
        upperPageIndex = this.perPage;
      }
      return this.filteredItems
        .map((item) => item.camundaTaskDto)
        .slice(lowerPageIndex, upperPageIndex);
    }
  },
  watch: {
    selectedTaskTypeFilters() {
      this.page = 1;
      this.$emit('onSelectedTaskTypeFiltersChange');
    },
    page() {
      this.$emit('onPaginationPageChanged', {
        page: this.page,
        perPage: this.perPage
      });
    },
    selectedId() {
      if (!this.selectedId) {
        this.selectedItem = undefined;
      }
    },
    visiblePages() {
      const index = this.getSelectionIndex(this.visiblePages);
      if (index === -1) {
        this.selectedItem = undefined;
      } else {
        this.selectedItem = this.selectedId;
      }
    }
  },
  methods: {
    onSortIndexChanged: function (event) {
      this.sortIndex = event;
      this.page = 1;
      this.$emit('onSortTypeChanged', event);
    },
    onSearchChanged: function (event) {
      this.regExp = event;
      this.page = 1;
      this.$emit('onSearchTextChanged', event);
    },
    filter: function () {
      return this.items.filter(
        (item) => item.title.match(this.regExp) !== null
      );
    },
    // sort function for followUp
    sortFunctionCreationDateAsc(a, b) {
      const dateA = new Date(a.camundaTaskDto.created).getTime();
      const dateB = new Date(b.camundaTaskDto.created).getTime();
      return dateA - dateB;
    },
    sortFunctionCreationDateDesc(a, b) {
      const dateA = new Date(a.camundaTaskDto.created).getTime();
      const dateB = new Date(b.camundaTaskDto.created).getTime();
      return dateB - dateA;
    },
    // sort function for followUp
    sortFunctionFollowUpAsc(a, b) {
      const dateA = new Date(a.camundaTaskDto.followUp).getTime();
      const dateB = new Date(b.camundaTaskDto.followUp).getTime();
      return dateA - dateB;
    },
    getSelectionIndex(array) {
      return array.findIndex((item) => item.id === this.selectedId);
    }
  }
};
</script>

<style scoped>
#list {
  display: grid;
  grid-template-rows: max-content auto;
  overflow: hidden;
}

#list-content {
  margin-top: var(--goe-spacing-2);
  overflow: auto;
}

.no-entries {
  text-align: center;
  padding: var(--goe-spacing-2);
  color: var(--goe-fontColor-light);
  opacity: 0.2;
  user-select: none;
}
</style>
