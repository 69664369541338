<template>
  <div class="action-view-wrapper">
    <h2 class="title">
      <div>Aufgabe erstellen</div>
      <div class="title-actions">
        <ImageButton
          :src="`${publicPath}close.png`"
          buttonType="clear"
          @onClick="$emit('onClose')"
        />
      </div>
    </h2>

    <div class="action-wrapper">
      <div class="action">
        <div class="input-section">
          <GoetelInput
            isMandatory
            description="Aufgabenname"
            type="text"
            inputId="name"
            :value="name"
            :currentErrors="errors"
            :visitedInputFields="visited"
            @onChange="name = $event"
            @onError="errors = $event"
            @onVisited="visited = $event"
          />

          <div class="assignee-wrapper">
            <div>Zuweisen an</div>
            <multiselect
              v-model="user"
              placeholder="Kein Benutzer ausgewählt"
              label="name"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              track-by="name"
              :options="users"
              :searchable="true"
              :allow-empty="true"
            >
              <template slot="noResult">
                Der eingegebene Benutzer wurde nicht gefunden.
              </template>
              <template slot="noOptions">
                Keine Benutzer vorhanden.
              </template>
            </multiselect>
          </div>

          <GoetelInput
            elementType="textarea"
            description="Beschreibung"
            type="text"
            inputId="description"
            :value="description"
            @onChange="description = $event"
          />
        </div>

        <div class="buttons-wrapper">
          <NormalButton
            description="Abbrechen"
            buttonStyle="clear"
            @onClick="$emit('onClose')"
          />
          <NormalButton
            description="Aufgabe erstellen"
            :disabled="errors.length > 0 || visited.length < 1"
            @onClick="create"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

import NormalButton from '@/components/elements/NormalButton.vue';
import GoetelInput from '@/components/elements/GoetelInput.vue';
import ImageButton from '@/components/elements/ImageButton.vue';

export default {
  name: 'CreateGenericTask',
  components: {
    ImageButton,
    NormalButton,
    GoetelInput
  },
  data: () => ({
    errors: [],
    visited: [],
    name: null,
    description: null,
    user: null,
    users: [],
    publicPath: process.env.BASE_URL
  }),
  created: function () {
    HTTP.get('/engine/default/user')
      .then((resp) => {
        this.users = resp.data.map((item) => ({
          ...item,
          name: `${item.firstName} ${item.lastName}`
        }));
      })
      .catch(() =>
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'error',
            'Die Benutzer konnten nicht geladen werden!'
          )
        )
      );
  },
  methods: {
    create: function () {
      HTTP.post('/engine/default/task/create', {
        assignee: this.user !== null ? this.user.id : null,
        description: this.description,
        name: this.name,
        priority: 50 // also hardcoded on camunda fe
      })
        .then(() => {
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'success',
              'Die Aufgabe wurde erfolgreich erstellt!'
            )
          );
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'error',
              'Die Aufgabe konnte nicht erfolgreich angelegt werden!'
            )
          )
        );
    }
  }
};
</script>

<style scoped>
.title {
  display: grid;
  grid-template-columns: auto max-content;
}

.buttons-wrapper {
  display: grid;
  grid-template-columns: auto max-content;
  grid-gap: var(--goe-spacing-1);
}

.assignee-wrapper > div:nth-child(1) {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
}

.assignee-wrapper > div:nth-child(2) {
  background: var(--goe-background-highlight);
}

.multiselect div {
  background: none !important;
  border: none !important;
}
</style>
